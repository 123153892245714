import React, { memo } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import { Dropdown } from '@johnlewispartnership/wtr-ingredients/ingredients/Dropdown';
import { DropdownItem } from '@johnlewispartnership/wtr-ingredients/ingredients/Dropdown/DropdownItem';
import { Radio } from '@johnlewispartnership/wtr-ingredients/ingredients/Radio';
import { LabeledControl } from '@johnlewispartnership/wtr-ingredients/ingredients/LabeledControl';

import styles from './SortBy.scss';

const BUTTON_NAME = 'SORT_BY';
const BUTTON_LABEL = 'Sort By';

const SortBy = memo(
  ({ currentValue, hasResults, isLoading, onChange, push, sortOptions, stickyFilter }) => {
    if (!hasResults) return null;

    const selected = sortOptions.find(({ value }) => value === currentValue);

    const handleSortSelect = (_event, menuItem) => {
      push({
        order: menuItem.dataset.itemValue,
        stickyFilter,
        noScroll: true,
        refinement: true,
      });

      onChange?.();
    };

    const accessibleLabel = selected ? (
      <>
        <span className="sr-only">{`${BUTTON_LABEL}, `}</span>
        {selected.label}
        <span className="sr-only">selected</span>
      </>
    ) : (
      BUTTON_LABEL
    );

    return (
      <section className={styles.sortByContainer}>
        <div className={styles.sortBy}>
          <Dropdown
            closeOnClick
            direction="bottom-end"
            disabled={isLoading}
            handleItemSelect={handleSortSelect}
            label={accessibleLabel}
            name={BUTTON_NAME}
            testId={BUTTON_NAME}
            value={currentValue}
            width="full"
            closeOnScroll
          >
            {sortOptions.map(({ label, value }) => {
              const checked = currentValue === value;

              return (
                <DropdownItem
                  aria-checked={checked}
                  component={LabeledControl}
                  control={<Radio readOnly tabIndex={-1} checked={checked} name={value} />}
                  data-item-value={value}
                  key={label}
                  label={label}
                  role="radio"
                />
              );
            })}
          </Dropdown>
        </div>
      </section>
    );
  },
);

SortBy.displayName = 'SortByNew';

SortBy.propTypes = {
  currentValue: string,
  hasResults: bool,
  isLoading: bool,
  onChange: func,
  push: func.isRequired,
  sortOptions: arrayOf(shape({ label: string, order: number, value: string })).isRequired,
  stickyFilter: bool,
};

SortBy.defaultProps = {
  currentValue: undefined,
  hasResults: false,
  isLoading: undefined,
  onChange: undefined,
  stickyFilter: false,
};

export default SortBy;
