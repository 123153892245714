import { push } from 'connected-react-router';

import { mergeQueryParams } from 'utils/searchQuery';
import { getLocationSearchParams, getPathname } from 'redux/modules/routing/selectors';

import { sortBy } from 'redux/modules/search/actions/sort-by';

export default ({ order, stickyFilter, noScroll = false, refinement = false }) =>
  (dispatch, getState) => {
    const state = getState();

    const sortQueryString = dispatch(sortBy({ order, stickyFilter }));
    const nextQueryString = mergeQueryParams(getLocationSearchParams(state), sortQueryString);

    return dispatch(
      push(`${getPathname(state)}${nextQueryString}`, {
        noScroll,
        refinement,
      }),
    );
  };
